#root,
.App {
  font-family: nunito;
  overflow-x: hidden;
  min-height: 100vh; /* Ensure the React root element takes at least 100% height */
  display: flex;
  flex-direction: column;
  background-color: "#f8f8fd";
}

.slider-container {
  flex-grow: 1; /* Makes the content grow to fill the space */
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.projectData {
  border: "2px solid black";
  border-radius: 5px;
}

.react-datepicker {
  min-width: 328px; /* Adjust as needed */
}

.modal-button {
  background-color: #282c34;
  border-color: #282c34;
  border-radius: 4px;
  font-family: nunito;
  cursor: pointer;
  color: white;
}

#closeModalButton {
  margin-top: 2px;
}

#submitModalButton {
  margin-left: 2px;
  padding-top: 2.5px;
}

.reportButton,
.downloadButton,
#dropdownMenuButton,
#cameraLinkButtons,
#submit,
#close,
.previousImage,
.nextImage {
  background-color: var(--button-background-color, #0d97be) !important;
  border-color: var(--button-background-color, #0d97be) !important;
  border: none;
}

.reportButton,
.downloadButton,
.previousImage,
.nextImage {
  border-radius: 3.5px;
}

#playButton {
  color: var(--button-background-color, #0d97be) !important;
}

#dropdownMenuButton {
  width: 117px;
}
#filterInput {
  width: 140px;
  height: 36px;
}

.slider-image {
  width: 100%;
  height: 100%;
}
.slider {
  width: 100%;
  height: 20%;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  max-width: 100%;
  height: 20%;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  position: relative;
}

.slider:hover {
  opacity: 1;
}

/* Customize the thumb (the draggable part) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--button-background-color, #0d97be) !important;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -3px; /* Adjust this value to vertically center the thumb */
  position: relative;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--button-background-color, #0d97be) !important;
  cursor: pointer;
  border-radius: 50%;
}

/* Customize the track (the line on which the thumb slides) */
.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd;
}

.slider::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #ddd;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #282c34; /* Black background with opacity */
  z-index: 1000; /* Ensure the overlay is on top */
}

@font-face {
  font-family: nunito;
  src: url(https://static.timelapserobot.com/assets/fonts/Nunito-Light.eot);
  src: local("Nunito Light"), local("Nunito-Light"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Light.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Light.woff)
      format("woff"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Light.ttf)
      format("truetype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Light.svg#Roboto)
      format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: nunito;
  src: url(https://static.timelapserobot.com/assets/fonts/Nunito-Regular.eot);
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Regular.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Regular.woff)
      format("woff"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Regular.ttf)
      format("truetype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Regular.svg#Roboto)
      format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: nunito;
  src: url(https://static.timelapserobot.com/assets/fonts/Nunito-Medium.eot);
  src: local("Nunito Medium"), local("Nunito-Medium"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Medium.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Medium.woff)
      format("woff"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Medium.ttf)
      format("truetype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Medium.svg#Roboto)
      format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: nunito;
  src: url(https://static.timelapserobot.com/assets/fonts/Nunito-SemiBold.eot);
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-SemiBold.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-SemiBold.woff)
      format("woff"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-SemiBold.ttf)
      format("truetype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-SemiBold.svg#Roboto)
      format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: nunito;
  src: url(https://static.timelapserobot.com/assets/fonts/Nunito-Bold.eot);
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Bold.woff)
      format("woff"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Bold.ttf)
      format("truetype"),
    url(https://static.timelapserobot.com/assets/fonts/Nunito-Bold.svg#Roboto)
      format("svg");
  font-weight: 700;
  font-style: normal;
}
